import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import { useI18n } from 'services/i18n';
import media from 'styles/media';
import { Background, Container, Row, Col } from 'styles/grid';
import Layout from 'components/layout';
import SEO from 'components/seo';
import Block from 'components/block';
import Links from 'components/links';
import Clock from 'images/icons/clock.svg';
import Check from 'images/icons/check.svg';
import { PrimaryTitle, SecondaryTitle, BiggerText, BigText } from 'components/texts';

const ListContainer = styled(Container)`
  padding: 30px 30px;
  ${media.md`
    padding: 60px 40px;
  `}
`;

const ListCol = styled(Col)`
  display: flex;
  text-align: left;
  align-items: center;
  svg {
    flex: 0 0 2em;
    margin-right: 2em;
    height: 2em;
    width: 2em;
  }
`;

const IntegrationPage = () => {
  const [t, language] = useI18n();
  const data = useStaticQuery(graphql`
    query {
      fr: allFile(filter: { relativeDirectory: { eq: "integration/fr" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 360, quality: 100) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
      en: allFile(filter: { relativeDirectory: { eq: "integration/en" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 360, quality: 100) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
  `);

  const imagesMapping = {
    fr: data.fr.nodes.reduce((mapping, image) => {
      mapping[image.name] = image.childImageSharp;
      return mapping;
    }, {}),
    en: data.en.nodes.reduce((mapping, image) => {
      mapping[image.name] = image.childImageSharp;
      return mapping;
    }, {}),
  };

  return (
    <Layout>
      <SEO
        title={t('seo.title.integration')}
        lang={language}
        description={t('seo.description.integration')}
      />
      <section>
        <Container>
          <Row justify="center" style={{ marginBottom: 40 }}>
            <Col md="content" style={{ textAlign: 'center' }}>
              <Clock height="4rem" width="4rem" />
            </Col>
          </Row>
          <Row justify="center">
            <Col sm={12} md={10} lg={8}>
              <PrimaryTitle align="center">{t('integration.title')}</PrimaryTitle>
            </Col>
          </Row>
          <Row justify="center">
            <Col sm={12} md={11} lg={10}>
              <BiggerText desktopAlign="center">{t('integration.tool')}</BiggerText>
            </Col>
          </Row>
        </Container>
      </section>
      <Background>
        <section>
          <ListContainer>
            <Row justify="space-around">
              <ListCol sm={12} md={5}>
                <Check />
                <BigText weight="bold" mobileAlign="left">
                  {t('integration.fitle')}
                </BigText>
              </ListCol>
              <ListCol sm={12} md={5}>
                <Check />
                <BigText weight="bold" mobileAlign="left">
                  {t('integration.plugin')}
                </BigText>
              </ListCol>
              <ListCol sm={12} md={5}>
                <Check />
                <BigText weight="bold" mobileAlign="left">
                  {t('integration.platform')}
                </BigText>
              </ListCol>
              <ListCol sm={12} md={5}>
                <Check />
                <BigText weight="bold" mobileAlign="left">
                  {t('integration.recommendation')}
                </BigText>
              </ListCol>
            </Row>
          </ListContainer>
        </section>
      </Background>
      <section>
        <Container>
          <Row>
            <Col>
              <SecondaryTitle style={{ marginBottom: 0 }}>
                {t('integration.integration')}
              </SecondaryTitle>
            </Col>
          </Row>
          <Block
            titleText={t('integration.collection')}
            imageFluid={imagesMapping[language].sizechart.fluid}
            isImageLeft={true}
            subtitle={'1'}
            alt={t('seo.alt.integration.SizeChart')}
          >
            <BigText>{t('integration.enough')}</BigText>
            <BigText>{t('integration.development')}</BigText>
          </Block>
          <Block
            titleText={t('integration.inte')}
            imageFluid={imagesMapping[language].script.fluid}
            isImageLeft={true}
            subtitle={'2'}
            alt={t('seo.alt.integration.PluginAndTracking')}
          >
            <BigText>{t('integration.set')}</BigText>
            <BigText>{t('integration.load')}</BigText>
          </Block>
          <Block
            titleText={t('integration.personalization')}
            imageFluid={imagesMapping[language].identity.fluid}
            isImageLeft={true}
            subtitle={'3'}
            alt={t('seo.alt.integration.Customisation')}
          >
            <BigText>{t('integration.free')}</BigText>
            <BigText>{t('integration.stages')}</BigText>
          </Block>
        </Container>
      </section>
      <Links withTitle={true} links={['plugin', 'dashboard']} />
    </Layout>
  );
};

export default IntegrationPage;
